@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.case-block {
  border: 0px solid #E5E7EB;
  background: linear-gradient(180deg, #DEDFE1 0%, rgba(255, 255, 255, 0.00) 10.12%, rgba(253, 253, 254, 0.05) 85.67%, #E3E3E4 100%), #FFF;
}
.wrapper{
  @include flex-column();
  gap: 24px;
  @include responsive-sizing("padding-inline", 8, 120, 360,1440);
  @include responsive-sizing("padding-block", 40, 80, 360,1440);

  .title{
    @include flex-row();
    justify-content: space-between;
    gap: 8px;
    align-items: center;

    h2{
      color: var(--Rich-black, #040F16);
      @include font-SegoeUI();
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
    }

    .btn{
      color: #FFFFFF;
      background: var(--Brand-Light-Guidance-Primary-Light-Primary, #566DD6);
    }
  }

}
.cards{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
  gap: 24px;

}