@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.case-card{
  position: relative;
  cursor: pointer;
  gap: 4px;
  @include flex-column();
  border-radius: var(--Indent_32, 32px);
  border: 0px solid var(--Brand-Light-Guidance-Primary-Light-Primary-Container, #BBC5EF);
  background: var(--SURFACE-SURFACE, #FFF);

  @include touch-hover(&){
    transition: $transition-default;
    border-radius: 32px;
    background: var(--Brand-Light-Guidance-Primary-Light-On-Primary, #F7F7FF);
    box-shadow: 0px 5px 22px 0px rgba(86, 109, 214, 0.12);
  }

  .image{
    border-radius: var(--Indent_32, 32px);
    height: 220px;
    width: 100%;
  }

  .text{
    padding: 0 16px 24px 16px;
    width: 100%;
    z-index: 1;
    h2{
      color: #040F16;
      @include text-Body1();
      font-weight: 400;
    }
    p{
      color: #2A2E37;
      @include text-Body2();
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}