@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.history-card{
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 16px;
  max-height: fit-content;
  height: 100%;
  flex: 1 0 50%;
  cursor: pointer;
  @include flex-column();
  align-items: flex-end;
  justify-content: space-between;
  border-radius: var(--Indent_32, 32px);
  box-shadow: 0px 0px 20px 0px rgba(39, 73, 97, 0.08);
  &:before{
    position: absolute;
    content: "";
    background: linear-gradient(174deg, rgba(0, 0, 0, 0.90) 3.73%, rgba(0, 0, 0, 0.00) 92.82%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: var(--Indent_32, 32px);
  }
  @include media-max(1100px){
    &{
      min-height: 228px;
    }
  }
  .content{
    height: 100%;
    width: 100%;
    z-index: 1;
    h3{
      color: #FFFFFF;
      @include text-Headline1();
      padding-bottom: 10px;
    }
    p{
      color: #FFFFFF;
      @include text-Body2();
      transition: $transition-default;
      padding: 0;
      margin: 0;


      &:hover{
        color: rgba(106, 106, 207, 0.81);
        border-bottom: 1px solid rgba(106, 106, 207, 0.81);
        padding-bottom: 3px;
      }
    }

    .list{
      margin: 0;
      padding: 0;
      list-style: none;
      @include flex-column();
      gap: 4px;
      .list-item{
        @include flex-row();
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid transparent;
        padding-bottom: 3px;
        transition: $transition-default;
        gap: 8px;

        .list-link{
          @include text-Body2();
          color: #FFFFFF;
          text-decoration: none;
          transition: $transition-default;
        }

        &:hover{
          border-bottom: 1px solid rgba(106, 106, 207, 0.81);

          .list-link{
            color: rgba(106, 106, 207, 0.81);
          }
          .list-icon{
            &>path{
              stroke: rgba(106, 106, 207, 0.81);

            }
          }
        }
      }
    }
  }

  @include touch-hover(&){
    .button{
      background: #fff;
      border:1px solid #FFFFFF;
      color: #6A6ACF;
      transition: $transition-default;
    }
  }
}