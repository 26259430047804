@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.service-card{
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 16px;
  height: 325px;
  flex: 1 0 254px;
  cursor: pointer;
  @include flex-column();
  align-items: flex-end;
  border-radius: var(--Indent_32, 32px);
  box-shadow: 0px 0px 20px 0px rgba(39, 73, 97, 0.08);
  &:before{
    position: absolute;
    content: "";
    background: linear-gradient(174deg, rgba(0, 0, 0, 0.90) 3.73%, rgba(0, 0, 0, 0.00) 92.82%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: var(--Indent_32, 32px);
  }

  .text{
    height: 100%;
    width: 100%;
    z-index: 1;
    h2{
      color: #FFFFFF;
      @include text-Headline1();
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p{
      color: #FFFFFF;
      @include text-Body2();
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @include touch-hover(&){
    .button{
      background: #fff;
      border:1px solid #FFFFFF;
      color: #6A6ACF;
      transition: $transition-default;
    }
  }
}