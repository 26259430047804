@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.services-block{
    border: 0px solid #E5E7EB;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 21.34%), linear-gradient(95deg, #F6FAFC 5.92%, #F7FAFC 25.08%, #FBFBFC 42.26%, #F9F9FB 52.38%, #F3F8FB 94.63%);
}
.wrapper{
    @include flex-column();
    gap: 24px;
    @include responsive-sizing("padding-inline", 8, 120, 360,1440);
    @include responsive-sizing("padding-top", 80, 120, 360,1440);
    @include responsive-sizing("padding-bottom", 40, 80, 360,1440);

    h2{
        color: var(--Rich-black, #040F16);
        @include font-SegoeUI();
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
    }
}

.cards{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}