@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.feedbacks{
  border: 0px solid #E5E7EB;
  background: linear-gradient(181deg, #FFF 0.61%, #DEDEDE 29.06%, #E4E4E5 40.61%, #E2E3E5 96.48%);
  padding-top: 40px;
  @include responsive-sizing("padding-bottom", 40, 80, 360,1440);

  .block{
    @include flex-column();
    gap: 24px;
    .title-wrapper{
      max-width: 1440px;
      @include responsive-sizing("padding-inline", 8, 120, 360,1440);

      width: 100%;
      margin: 0 auto;
      @include flex-row();
      align-items: flex-start;
    }

    .title{
      color: var(--Rich-black, #040F16);
      @include font-SegoeUI();
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
    }
  }
}

.carousel{

  [data-pc-section="itemscontainer"]{
    justify-content: space-between;
    gap: 32px;
    width: 100%;
  }

}

.slide-item{
  display: flex;
  justify-content: center;
}

.card{
  position: relative;
  width: fit-content;

  &.video{
    border-radius: 100px;
    .image{
      border-radius: 182px;
      img{
        border-radius: 182px;

      }

    }
  }
  .image{
    padding: 9px;
    border-radius: var(--Indent_24, 24px);
    border: 4px solid var(--Brand-Light-Guidance-Primary-Light-Primary, #566DD6);
    img{
      border-radius: 16px;
      background: lightgray -6.14px -4.84px / 116.322% 103.192% no-repeat;
    }
  }

  .info{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-52%, 0);
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--Brand-Light-Guidance-Neutral-Light-On-Surface, #C9C5CA);
    background: var(--Brand-Light-Guidance-Neutral-Light-Surface, #FFF);

    .post{
      color: var(--Brand-Light-Guidance-Neutral-Light-On-Background-Container, #1C1B1F);
      font-feature-settings: 'liga' off, 'clig' off;

      /* Body3 */
      @include font-SegoeUI();
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
    }
  }
}