@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.modal-faq {
  [data-pc-section="header"] {
    padding-block: 34px;
  }
  .wrapper, .text-list{
    @include flex-column();
    gap: 16px;
  }
  .title, .text, .list {
    @include flex-column();
  }


}