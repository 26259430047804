@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.cooperation-block {
    border: 0px solid #E5E7EB;
    background: linear-gradient(180deg, #DEDFE1 0%, rgba(255, 255, 255, 0.00) 10.12%, rgba(253, 253, 254, 0.05) 85.67%, #E3E3E4 100%), #FFF; .wrapper {
    @include responsive-sizing("padding-inline", 8, 120, 360,1440);
    @include responsive-sizing("padding-block", 40, 80, 360,1440);
    @include flex-column;
    gap: 24px;
  }
}
.top{
  @include flex-column;
  gap: 24px;
  @include responsive-sizing("padding-bottom", 40, 80, 360,1440);

  .title{
      color: var(--Rich-black, #040F16);
      @include font-SegoeUI();
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
  }
  .cards{
    @include flex-row;
    gap: 24px;

    & > div{
      flex: 0 1 50%;
      min-height: 160px;
      justify-content: space-between;
    }
    & >div:last-child{
      margin-top: 20px;
    }
    @include media-max(720px){
      @include flex-column();
    }
  }
}